import React from "react";
import "./Body.css";
//PAGES
import Header from "../Header";
import Servicios from "../Servicios";
import Nosotros from "../Nosotros";
import Clientes from "../Clientes";
import Footer from "../Footer";
import BtnWha from "../BtnWha";
function Body({ theme }) {
  return (
    <>
      <Header theme={theme} />
      <Servicios />
      <Nosotros />
      <Clientes />
      <Footer />
      <BtnWha />
    </>
  );
}

export default Body;
