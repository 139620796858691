import {
  query,
  where,
  getDocs,
  getDoc,
  collection,
  serverTimestamp,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase";

const fglobales = {};

fglobales.getExistNoId = async (collectionText, variableText, value) => {
  const queryRef = query(
    collection(db, collectionText),
    where(variableText, "==", value)
  );
  const docSnap = await getDocs(queryRef);

  return docSnap.size > 0;
};

fglobales.getDocId = async (collectionText, id) => {
  const q = doc(db, collectionText, id);
  return await getDoc(q);
};

fglobales.onDocId = async (collectionText, id) => {
  const q = doc(db, collectionText, id);
  return onSnapshot(q);
};

fglobales.addDocs = async (collectionText, data, dataUser) => {
  const ref = doc(collection(db, collectionText));
  try {
    await setDoc(ref, {
      ...data,
      id: ref.id,
      timestamp: serverTimestamp(),
      creatorUser: dataUser,
    });
  } catch (e) {
    console.log("Transacción fallida: ", e);
  }
};

fglobales.addDocId = async (collectionText, data, id) => {
  const q = doc(db, collectionText, id);
  return await setDoc(q, data);
};

fglobales.updateDoc = async (collectionText, id, data) => {
  const q = doc(db, collectionText, id);
  try {
    await updateDoc(q, data);
    return true;
  } catch (e) {
    return false;
  }
};

fglobales.deleteDoc = async (collectionText, id) => {
  const q = doc(db, collectionText, id);
  return await deleteDoc(q);
};

fglobales.getConfig = async (collectionText) => {
  const querySnapshot = await getDocs(collection(db, collectionText));
  var data = {};
  querySnapshot.forEach((doc) => {
    data[doc.id] = doc.data();
  });
  return data;
};

fglobales.getCustomers = async (collectionText) => {
  const querySnapshot = await getDocs(collection(db, collectionText));
  let result = []; // clear previous results
  querySnapshot.forEach((doc) => {
    let data = doc.data();
    let docData = {
      id: data.id,
      name: data.businessName,
      email: data.email,
      nit: data.nit,
    };
    result.push(docData);
  });
  return result;
};

fglobales.getCountries = async () => {
  const querySnapshot = await getDocs(collection(db, "countries"));
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};

fglobales.getDepartments = async (docText) => {
  const q = query(
    collection(db, "countries", docText, "states"),
    orderBy("nombre")
  );

  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};

fglobales.getMunicipalities = async (docTextC, docTextS) => {
  const q = query(
    collection(db, "countries", docTextC, "states", docTextS, "municipalities"),
    orderBy("municipality")
  );

  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};

fglobales.getUsersDatos = async (collectionText, variableText, value) => {
  const q = query(
    collection(db, collectionText),
    where(variableText, "==", value)
  );
  const querySnapshot = await getDocs(q);
  let docData = [];

  querySnapshot.forEach((doc) => {
    docData = doc.data();
  });

  return docData;
};

fglobales.getDatosAB = async (collectionText, variableText, value) => {
  const q = query(
    collection(db, collectionText),
    where(variableText, "==", value),
    orderBy("date", "desc")
  );
  const querySnapshot = await getDocs(q);
  let docData = [];

  querySnapshot.forEach((doc) => {
    docData.push({ ...doc.data() });
  });

  return docData;
};

fglobales.getDatosABC = async (collectionText, variableText, value) => {
  const q = query(
    collection(db, collectionText),
    where(variableText, "in", value)
  );
  const querySnapshot = await getDocs(q);
  let docData = [];

  querySnapshot.forEach((doc) => {
    docData.push({ ...doc.data() });
  });

  return docData;
};

fglobales.getDatosCli = async (collectionText, variableText, value) => {
  const q = query(
    collection(db, collectionText),
    where(variableText, "==", value)
  );
  const querySnapshot = await getDocs(q);
  let docData = [];

  querySnapshot.forEach((doc) => {
    docData.push({ ...doc.data() });
  });

  return docData;
};

fglobales.getSur = async (collectionText, id) => {
  const q = doc(db, collectionText, id);
  const docQ = await getDoc(q);

  if (docQ.exists()) {
    return docQ.data();
  }
};

export default fglobales;
