import React, { useState } from "react";
import "./Nosotros.css";
import fondImg from "../../assets/images/team.bg.png";
import moisesImg from "../../assets/images/Moises-Polo.png";
import betoImg from "../../assets/images/Alberto-Prieto.png";
// import joseImg from "../../assets/images/Jose-Araque.png";
import fidelImg from "../../assets/images/Fidel-Gonzalez.png";
import manuelImg from "../../assets/images/Manuel-Araque.png";
import daniImg from "../../assets/images/Daniela-Valencia.png";
// import josePerezImg from "../../assets/images/Jose-Perez.png";
import williamImg from "../../assets/images/William-Gallardo.png";

function Nosotros() {
  const [open, setOpen] = useState(false);
  return (
    <div className='grid grid-nogutter p-6 md:p-8 nosotrosCon aos-init'>
      <div className='col-12 text-center'>
        <h2>ACERCA DE NOSOTROS</h2>
        <p
          className={!open ? "hvr-wobble-vertical pt-4" : "pt-4"}
          onClick={() => {
            setOpen(!open);
          }}>
          <i className='pi pi-arrow-down hvr-icon'></i>
        </p>
      </div>

      <div className={!open ? "col-12  mt-5 desper" : "col-12  mt-5 normal"}>
        <div
          className='grid'
          data-aos='fade-right'>
          {/*Fidel-Gonzalez*/}
          <div className='col-12 md:col-2'>
            <div className='cardTeam'>
              <img
                src={fondImg}
                className='bgTeam'
                alt='Fondo'
              />
              <img
                src={fidelImg}
                className='oneTeam'
                alt='Moisés Polo'
              />
              <h3>Fidel González</h3>
              <h4>CEO & Director creativo</h4>
              <p>
                Diseñador senior con 7 años de experiencia en el mundo digital.
                Especializado en brand management, social media, edición de
                video, marketing digital, SEM y diseño web.
              </p>

              <div className='wrapper mt-3'>
                <a
                  href='https://www.instagram.com/fidegz/'
                  className='icon github'
                  target='_blank'
                  rel='noreferrer'>
                  <div className='tooltip'>Instagram</div>
                  <span>
                    <i className='fab fa-instagram'></i>
                  </span>
                </a>
                <a
                  href='https://www.linkedin.com/in/fidegonz/'
                  target='_blank'
                  rel='noreferrer'
                  className='icon github'>
                  <div className='tooltip'>Linkedin</div>
                  <span>
                    <i className='fab fa-linkedin'></i>
                  </span>
                </a>
                <a
                  href='https://www.behance.net/fidegz'
                  target='_blank'
                  rel='noreferrer'
                  className='icon github'>
                  <div className='tooltip'>Behance</div>
                  <span>
                    <i className='fab fa-behance'></i>
                  </span>
                </a>
              </div>
            </div>
          </div>

          {/*Alberto Prieto*/}
          <div className='col-12 md:col-2'>
            <div className='cardTeam'>
              <img
                src={fondImg}
                className='bgTeam'
                alt='Fondo'
              />
              <img
                src={betoImg}
                className='oneTeam'
                alt='Moisés Polo'
              />
              <h3>Alberto Prieto</h3>
              <h4>Desarrollador web Senior</h4>
              <p>
                Ingeniero de sistemas y Webmaster. Soy una persona con amplia
                experiencia, capacitada, proactiva, ingeniosa, amable, empatico,
                responsable, perseverante, que busca y le gusta adquirir nuevos
                conocimientos cada día.
              </p>

              <div className='wrapper mt-3'>
                <a
                  href='https://www.instagram.com/alberto.prietoo/'
                  className='icon github'
                  target='_blank'
                  rel='noreferrer'>
                  <div className='tooltip'>Instagram</div>
                  <span>
                    <i className='fab fa-instagram'></i>
                  </span>
                </a>
                {/* <a
                  href='https://www.linkedin.com/in/moises-polo-83991023a/'
                  target='_blank'
                  rel='noreferrer'
                  className='icon github'>
                  <div className='tooltip'>Linkedin</div>
                  <span>
                    <i className='fab fa-linkedin'></i>
                  </span>
                </a>
                <a
                  href='https://github.com/MoiisesDaviid'
                  target='_blank'
                  rel='noreferrer'
                  className='icon github'>
                  <div className='tooltip'>Github</div>
                  <span>
                    <i className='fab fa-github'></i>
                  </span>
                </a> */}
              </div>
            </div>
          </div>

          {/*Moisés Polo*/}
          <div className='col-12 md:col-2'>
            <div className='cardTeam'>
              <img
                src={fondImg}
                className='bgTeam'
                alt='Fondo'
              />
              <img
                src={moisesImg}
                className='oneTeam'
                alt='Moisés Polo'
              />
              <h3>Moisés Polo</h3>
              <h4>Desarrollador web Senior</h4>
              <p>
                Ingeniero de Sistemas con más de 3 años de experiencia en
                desarrollo web. Especializado en tecnologías front-end y
                back-end como React, JavaScript, HTML5, CSS3, Firebase y
                WordPress. También competente en el desarrollo de aplicaciones
                de escritorio y web con Java, Oracle, PHP y MySQL.
              </p>

              <div className='wrapper mt-3'>
                <a
                  href='https://www.instagram.com/moiisespolo/?igshid=ZGUzMzM3NWJiOQ%3D%3D'
                  className='icon github'
                  target='_blank'
                  rel='noreferrer'>
                  <div className='tooltip'>Instagram</div>
                  <span>
                    <i className='fab fa-instagram'></i>
                  </span>
                </a>
                <a
                  href='https://www.linkedin.com/in/moises-polo-83991023a/'
                  target='_blank'
                  rel='noreferrer'
                  className='icon github'>
                  <div className='tooltip'>Linkedin</div>
                  <span>
                    <i className='fab fa-linkedin'></i>
                  </span>
                </a>
                {/* <a
                  href='https://github.com/MoiisesDaviid'
                  target='_blank'
                  rel='noreferrer'
                  className='icon github'>
                  <div className='tooltip'>Github</div>
                  <span>
                    <i className='fab fa-github'></i>
                  </span>
                </a> */}
              </div>
            </div>
          </div>

          {/*Manuel Araque*/}
          <div className='col-12 md:col-2'>
            <div className='cardTeam'>
              <img
                src={fondImg}
                className='bgTeam'
                alt='Fondo'
              />
              <img
                src={manuelImg}
                className='oneTeam'
                alt='Moisés Polo'
              />
              <h3>Manuel Araque</h3>
              <h4>Diseñador Grafico E-learning</h4>
              <p>
                Diseñador con experiencia en e-learning, animación e
                ilustración. Conocimientos avanzados en Adobe (After Effects,
                Illustrator, Audition, Photoshop), Storyline y Blender. 8 años
                dedicados a potenciar ideas.
              </p>

              <div className='wrapper mt-3'>
                <a
                  href='https://www.instagram.com/ma_ar.art/'
                  className='icon github'
                  target='_blank'
                  rel='noreferrer'>
                  <div className='tooltip'>Instagram</div>
                  <span>
                    <i className='fab fa-instagram'></i>
                  </span>
                </a>
                <a
                  href='https://www.behance.net/Mel-Maar'
                  target='_blank'
                  rel='noreferrer'
                  className='icon github'>
                  <div className='tooltip'>Behance</div>
                  <span>
                    <i className='fab fa-behance'></i>
                  </span>
                </a>
              </div>
            </div>
          </div>

          {/*Daniela Valencia*/}
          <div className='col-12 md:col-2'>
            <div className='cardTeam'>
              <img
                src={fondImg}
                className='bgTeam'
                alt='Fondo'
              />
              <img
                src={daniImg}
                className='oneTeam'
                alt='Moisés Polo'
              />
              <h3>Daniela Valencia</h3>
              <h4>Diseñadora UI - Diseñadora 3D</h4>
              <p>
                Diseñadora gráfica con enfoque en diseño de interfaces y
                diseñadora de espacios 3d, soy pro activa, con estética versátil
                para adaptarme a los proyectos, me gusta estar en constate
                aprendizaje y explorar nuevas tendencias.
              </p>

              <div className='wrapper mt-3'>
                <a
                  href='https://www.instagram.com/borealilustracion/'
                  className='icon github'
                  target='_blank'
                  rel='noreferrer'>
                  <div className='tooltip'>Instagram</div>
                  <span>
                    <i className='fab fa-instagram'></i>
                  </span>
                </a>
                {/* <a
                  href="https://www.linkedin.com/in/moises-polo-83991023a/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon github"
                >
                  <div className="tooltip">Linkedin</div>
                  <span>
                    <i className="fab fa-linkedin"></i>
                  </span>
                </a>
                <a
                  href="https://github.com/MoiisesDaviid"
                  target="_blank"
                  rel="noreferrer"
                  className="icon github"
                >
                  <div className="tooltip">Github</div>
                  <span>
                    <i className="fab fa-github"></i>
                  </span>
                </a> */}
              </div>
            </div>
          </div>

          {/*Willian Gallardo*/}
          <div className='col-12 md:col-2'>
            <div className='cardTeam'>
              <img
                src={fondImg}
                className='bgTeam'
                alt='Fondo'
              />
              <img
                src={williamImg}
                className='oneTeam'
                alt='Moisés Polo'
              />
              <h3>Willian Gallardo</h3>
              <h4>Ventas (chile)</h4>
              <p>
                Sólida experiencia, enfocado en alcanzar objetivos y cultivar
                relaciones comerciales. Impulsando el crecimiento de la empresa
                con pasión y resultados tangibles.
              </p>
              <div className='wrapper mt-3'>
                <a
                  href='https://www.linkedin.com/in/willian-jos%C3%A9-gallardo-uribe-4a1046117/'
                  target='_blank'
                  rel='noreferrer'
                  className='icon github'>
                  <div className='tooltip'>Linkedin</div>
                  <span>
                    <i className='fab fa-linkedin'></i>
                  </span>
                </a>

                {/* <a
                  href='https://github.com/MoiisesDaviid'
                  target='_blank'
                  rel='noreferrer'
                  className='icon github'>
                  <div className='tooltip'>Github</div>
                  <span>
                    <i className='fab fa-github'></i>
                  </span>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nosotros;
