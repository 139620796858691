import React from "react";
import "./BtnWha.css";
//MEDIA
import whaIcon from "../../assets/images/wha-icon.png";
//PAGES

function BtnWha() {
  return (
    <>
      <a
        href='https://wa.me/593963882316?text=Hola,%20me%20gustaría%20saber%20más%20sobre%20sus%20servicios.%20¿Podrían%20ayudarme%3F'
        className='whatsapp-float'
        target='_blank'
        rel='noreferrer'>
        <img
          src={whaIcon}
          alt='WhatsApp'
        />
      </a>
    </>
  );
}

export default BtnWha;
