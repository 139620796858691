import React from "react";
import "./Error404.css";
import ErrorImg from "../../assets/images/error404.png";
import { Image } from "primereact/image";

function Error404() {
  return (
    <>
      <div className="imgError text-center">
        <Image src={ErrorImg} alt="ErrorImg" />
      </div>
    </>
  );
}

export default Error404;
