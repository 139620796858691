import React from "react";
import "./Servicios.css";
//MEDIOS
import Desarrolloweb from "../../assets/images/desarrolloweb.png";
import DisenoUI from "../../assets/images/uiux.png";
import Branding from "../../assets/images/branding.png";
import Socialmedia from "../../assets/images/socialmedia.png";

function Servicios() {
  return (
    <>
      <div className='grid serviciosCon'>
        <div className='col-12 md:col-6 p-5 md:p-8'>
          <h4>SERVICIOS</h4>
          <h2>ÁREAS DE ENFOQUE</h2>
          <p className='pt-2'>
            Creamos marcas atractivas y experiencias digitales que definen la
            cultura y elevan las marcas para lograr un crecimiento sin
            precedentes.
          </p>
        </div>

        <div className='grids'>
          <figure className='effect-romeo'>
            <img
              src={Desarrolloweb}
              alt='Desarrollo web'
            />
            <figcaption>
              <h2>
                DESARROLLO <span>WEB</span>
              </h2>
              <p>
                Creación de sitios web dinámicos, responsivos y personalizados
                que reflejan la esencia de tu marca, <br />
                optimizados para una experiencia de usuario superior.
              </p>
              {/* <a href="www.google.com">View more</a> */}
            </figcaption>
          </figure>

          <figure className='effect-romeo'>
            <img
              src={DisenoUI}
              alt='Diseño UI/UX'
            />
            <figcaption>
              <h2>
                DISEÑO <span>UI/UX</span>
              </h2>
              <p>
                Creación de interfaces intuitivas y atractivas para experiencias
                <br />
                digitales memorables que fusionan diseño y funcionalidad de
                forma excepcional.
              </p>
              {/* <a href="www.google.com">View more</a> */}
            </figcaption>
          </figure>

          <figure className='effect-romeo'>
            <img
              src={Socialmedia}
              alt='Socialmedia'
            />
            <figcaption>
              <h2>
                SOCIAL <span>MEDIA</span>
              </h2>
              <p>
                Estrategias efectivas en redes sociales para potenciar <br />
                tu presencia online, aumentar la interacción con tu audiencia y
                fortalecer tu marca.
              </p>
              {/* <a href="www.google.com">View more</a> */}
            </figcaption>
          </figure>

          <figure className='effect-romeo'>
            <img
              src={Branding}
              alt='Branding'
            />
            <figcaption>
              <h2>
                BRANDING<span></span>
              </h2>
              <p>
                Construcción de identidades visuales sólidas y coherentes <br />
                que destacan los valores de tu marca, transmiten tu mensaje y
                generan conexiones emocionales.
              </p>
              {/* <a href="www.google.com">View more</a> */}
            </figcaption>
          </figure>
        </div>
      </div>
    </>
  );
}

export default Servicios;
