import React, { useEffect, useState } from "react";
// ESTILOS
import "./Header.css";
// PRIMEREACT
import { Carousel } from "primereact/carousel";
// COMPONENTES
import Loader from "../Loader";
// SERVICIOS
import fglobales from "../../services/fglobales";

function Header({ theme }) {
  const [projectsdata, setProjectsdata] = useState(null);
  useEffect(() => {
    const miFuncionAsincrona = async () => {
      try {
        const docPro = await fglobales.getDatosAB("projects", "status", true);

        setProjectsdata(docPro);
      } catch (error) {
        console.log(error);
      }
    };

    miFuncionAsincrona();
  }, []);

  const responsiveOptions = [
    {
      breakpoint: "1200px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "1024px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "600px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const productTemplate = (pro) => {
    return (
      <div className='grid grid-nogutter'>
        <div className='col-12 proyectoImg pb-6 centrar'>
          <img
            src={pro.imgUrl}
            onError={(e) =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            alt={pro.name}
            className='proyectoImg'
          />

          <div className='p-2'>
            <h3 className='pb-2'>{pro.title}</h3>
            <p className='descPro pb-2 md:text-sm'>{pro.desc}</p>

            <a
              href={pro.urlExt}
              target='_blank'
              rel='noreferrer'
              className='hvr-icon-wobble-horizontal pt-4'>
              <i className='pi pi-arrow-right hvr-icon colorIcon'></i>
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='header-container'>
      <video
        src={theme.bkEscritorio}
        autoPlay={true}
        muted
        loop
        playsInline
        className='background-video'></video>

      <div className='grid grid-nogutter'>
        <div className='col-12 logo z-2'>
          <div className='grid'>
            <div className='col-4 md:col-2 col-offset-8 md:col-offset-10 logoImg'>
              <img
                src={theme ? theme.logo : "logo"}
                alt='Logo'
              />
            </div>

            <div className='col-12'>
              <div className='grid'>
                <div className='col-12 md:col-5'>
                  <p className='tHeader'>
                    BIENVENIDOS A <br /> NUESTRA AGENCIA
                    <span className='separator'>
                      &nbsp;DIGITAL &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className='col-12 pt-4 md:pt-8 proyectContent align-content-center flex-wrap'>
              <div className='grid flex align-items-center justify-content-center'>
                <div className='col-12 md:col-4 md:pl-6 pl-4 lg:col-3'>
                  <h4>NUESTRO TRABAJO</h4>
                  <h2>PROYECTOS DESTACADOS</h2>
                  <p className='pt-2'>
                    La estrategia a prueba de balas se combina con una ejecución
                    perfecta y creativa que define la categoría.
                  </p>

                  <a
                    href='https://narastudio-portafolio.web.app/'
                    target='_blank'
                    rel='noreferrer'>
                    <button className='btn-web hvr-icon-wobble-horizontal mt-3 mb-3'>
                      VER TODO &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <i className='pi pi-arrow-right hvr-icon'></i>
                    </button>
                  </a>
                </div>

                <div className='col-12 md:col-8 md:pl-6 lg:col-9'>
                  {projectsdata ? (
                    <Carousel
                      value={projectsdata}
                      numVisible={3}
                      numScroll={1}
                      responsiveOptions={responsiveOptions}
                      circular
                      autoplayInterval={10000}
                      itemTemplate={productTemplate}
                      // showItemNavigators
                      showIndicators
                    />
                  ) : (
                    <Loader typeLoader={1} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
