import React, { useEffect, useState } from "react";
import "./Clientes.css";
// COMPONENTES
import Loader from "../Loader";
// SERVICIOS
import fglobales from "../../services/fglobales";

function Clientes() {
  const [dataClientes, setDataClientes] = useState(null);
  console.log("🚀 ~ Clientes ~ dataClientes:", dataClientes);

  useEffect(() => {
    const miFuncionAsincrona = async () => {
      try {
        const docPro = await fglobales.getDatosCli("customers", "status", true);

        setDataClientes(docPro);
      } catch (error) {
        console.log(error);
      }
    };

    miFuncionAsincrona();
  }, []);
  return (
    <>
      <div className='grid grid-nogutter p-5 footer'>
        <div className='col-12 md:col-10 md:col-offset-1'>
          <div className='brand-wheel'>
            <div className='brand-slide'>
              {dataClientes ? (
                dataClientes.map((cli, m) => {
                  return (
                    <div
                      className='logo-div'
                      key={m}>
                      <img
                        src={cli.imgUrl}
                        alt={cli.name}
                      />
                    </div>
                  );
                })
              ) : (
                <Loader typeLoader={0} />
              )}
            </div>

            <div className='brand-slide delay'>
              {dataClientes ? (
                dataClientes.map((cli, m) => {
                  return (
                    <div
                      className='logo-div'
                      key={m}>
                      <img
                        src={cli.imgUrl}
                        alt={cli.name}
                      />
                    </div>
                  );
                })
              ) : (
                <Loader typeLoader={0} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Clientes;
