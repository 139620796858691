import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import AOS from "aos";
import "aos/dist/aos.css";
//ESTILOS
import "./App.css";
//PAGES
import Loader from "./pages/Loader";
import Error404 from "./pages/Error404";
import Body from "./pages/Body";
//FIREBASE
import { onSnapshot, query, doc } from "firebase/firestore";
import { db } from "./firebase";

function App() {
  const [configurations, setConfigurations] = useState([]);
  const [theme, setTheme] = useState([]);

  //Datos de configuración
  useEffect(() => {
    const qq = query(doc(db, "config", "configurations"));
    const unlistenC = onSnapshot(qq, (docs) => {
      let configF = docs.data();
      setConfigurations(configF);
    });
    return () => {
      unlistenC();
    };
  }, []);

  //Datos de Thema
  useEffect(() => {
    const qq = query(doc(db, "config", "theme"));
    const unlistenC = onSnapshot(qq, (docs) => {
      let configTh = docs.data();
      setTheme(configTh);
    });
    return () => {
      unlistenC();
    };
  }, []);

  //AOS Animations
  useEffect(() => {
    AOS.init({
      duration: 1100,
      offset: 120,
      easing: "ease-out-quart",
      mirror: true,
      once: false,
    });
  }, []);

  if (configurations.length === 0 && theme.length === 0) {
    return <Loader typeLoader={0} />;
  }

  return (
    <>
      {configurations && configurations.on === false ? (
        <Error404 />
      ) : (
        <Body theme={theme} />
      )}
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
