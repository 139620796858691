import React from "react";
import MoonLoader from "react-spinners/MoonLoader";
//ESTILOS
import "./Loader.css";

function Loader({ typeLoader }) {
  return (
    <>
      <div
        className={
          typeLoader === 0 ? "containerLoader" : "containerLoaderSimple"
        }>
        <MoonLoader
          color='var(--primary-color)'
          size={78}
          speedMultiplier={0.3}
        />
      </div>
    </>
  );
}

export default Loader;
