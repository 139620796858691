import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className='grid p-5 footer'>
      <div className='col-12 text-center borderT'>
        <h2 className='mt-6'>CONTÁCTANOS</h2>
        <p className='p-5'>
          <a
            href='mailto:hello@narastudio.pro'
            className='footerC hvr-grow'>
            hello@narastudio.pro
          </a>
        </p>

        <div className='grid'>
          <div className='col-12 lg:col-10 lg:col-offset-1 xl:col-8 xl:col-offset-2'>
            <div className='grid'>
              <div className='col-6 md:col-4'>
                <p className='telContainer boderL'>Ecu. +593 96 388 2316</p>
              </div>
              <div className='col-6 md:col-4'>
                <p className='telContainer boderL2'>Col. +57 311 660 2692</p>
              </div>
              <div className='col-6 md:col-4'>
                <p className='telContainer '>Cl. +56 9 6450 0290</p>
              </div>
              {/* <div className="col-6 md:col-3">
                <p className="telContainer">Esp. +34 673411 280</p>
              </div> */}
            </div>
          </div>
        </div>

        <div className='grid mt-6 pt-4 borderT'>
          <div className='col-12 md:col-6 md:col-offset-3 p-3'>
            <div className='grid'>
              <div className='social-buttons'>
                <a
                  href='www.google.com'
                  target='_blank'
                  className='social-button social-button-efect'>
                  <i className='fa-brands fa-facebook-f'></i>
                </a>
                <a
                  href='www.google.com'
                  target='_blank'
                  className='social-button social-button-efect '>
                  <i className='fa-brands fa-instagram'></i>
                </a>
                <a
                  href='www.google.com'
                  target='_blank'
                  className='social-button social-button-efect '>
                  <i className='fa-brands fa-twitter'></i>
                </a>
                <a
                  href='www.google.com'
                  target='_blank'
                  className='social-button social-button-efect '>
                  <i className='fa-brands fa-tiktok'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
